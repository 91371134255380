import { theme, themeOverrides } from '../../theme';
import { type Theme } from '@mui/material/styles/createTheme';
import { createContext, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { palette } from '../palette';
import { createTheme } from '@mui/material/styles';
import { BrandingResponse } from '../../app/services/api/generated';

const ThemeContext = createContext<[Theme, (branding: BrandingResponse | null) => void]>([theme, () => {}]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDynamicBranding = (brandingData: any): Theme => {
  theme.palette.primary = brandingData.primaryColors;
  theme.palette.secondary = brandingData.secondaryColors;
  const themeToConfig = themeOverrides;
  themeToConfig.palette.primary = brandingData.primaryColors;
  themeToConfig.palette.secondary = brandingData.secondaryColors;
  const newtheme = createTheme(themeToConfig);
  return newtheme;
};

const getDefaultBranding = (): Theme => {
  theme.palette.primary = palette.defaultPrimary;
  theme.palette.secondary = palette.defaultSecondary;
  return theme;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ThemeProviderContext = ({ children }: any) => {
  const [currentTheme, setTheme] = useState(() => {
    const storedBrandingJson = localStorage.getItem('branding');

    if (storedBrandingJson === null) {
      return getDefaultBranding();
    } else {
      const storedBranding = JSON.parse(storedBrandingJson);
      return getDynamicBranding(storedBranding);
    }
  });

  const changeBranding = (branding: BrandingResponse | null) => {
    if (branding != null) {
      const newBranding = {
        id: branding.id,
        name: branding.name,
        franchiseId: branding.franchiseId,
        logoImage: 'data:image/png;base64,' +  branding.logoImage,
        dashboardImage: 'data:image/png;base64,' + branding.dashboardImage,
        primaryColors: {
          main: branding.primaryColors?.color500,
          light: branding.primaryColors?.color300,
          dark: branding.primaryColors?.color800,
          contrastText: branding.primaryColors?.contrastTextColor,
          950: branding.primaryColors?.color950,
          900: branding.primaryColors?.color900,
          800: branding.primaryColors?.color800,
          700: branding.primaryColors?.color700,
          600: branding.primaryColors?.color600,
          500: branding.primaryColors?.color500,
          400: branding.primaryColors?.color400,
          300: branding.primaryColors?.color300,
          200: branding.primaryColors?.color200,
          100: branding.primaryColors?.color100,
          50: branding.primaryColors?.color50,
          25: branding.primaryColors?.color25,
        },
        secondaryColors: {
          main: branding.secondaryColors?.color500,
          light: branding.secondaryColors?.color300,
          dark: branding.secondaryColors?.color800,
          contrastText: branding.secondaryColors?.contrastTextColor,
          950: branding.secondaryColors?.color950,
          900: branding.secondaryColors?.color900,
          800: branding.secondaryColors?.color800,
          700: branding.secondaryColors?.color700,
          600: branding.secondaryColors?.color600,
          500: branding.secondaryColors?.color500,
          400: branding.secondaryColors?.color400,
          300: branding.secondaryColors?.color300,
          200: branding.secondaryColors?.color200,
          100: branding.secondaryColors?.color100,
          50: branding.secondaryColors?.color50,
          25: branding.secondaryColors?.color25,
        },
      };

      localStorage.setItem('branding', JSON.stringify(newBranding));
      setTheme(getDynamicBranding(newBranding));
    } else {
      localStorage.removeItem('branding');
      setTheme(getDefaultBranding);
    }
  };

  return (
    <ThemeContext.Provider value={[currentTheme, changeBranding]}>
      <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeProviderContext, ThemeContext };