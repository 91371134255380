
import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { theme } from '../../../../theme';
import { DrawerContext, DrawerValues } from '../contexts/DrawerContext';
import { DrawerNavItem } from '../interfaces/DrawerNavItem';

export const NavItem = (props: { item: DrawerNavItem, onClick: (() => void) | undefined }) => {
  const { t } = useTranslation('drawer');
  const { pathname, search } = useLocation();
  const { isHighLighted, selectedNavItem, isMobile } = useContext(DrawerContext) as DrawerValues;
  const showAsHighlighted = isHighLighted(props.item, pathname, search);
  const selectedNavItemToUse = isMobile ? selectedNavItem : undefined;
  const isCurrentSelected = props.item.translationKey === selectedNavItemToUse?.translationKey;

  const colourToUse = () => {
    if (showAsHighlighted) return theme.palette.primary[400];
    if (isCurrentSelected) return theme.palette.primary[500];
    return 'transparent';
  };
  const unSelectedColor = theme.palette.primary.contrastText == '#000000' ? theme.palette.primary[900] : theme.palette.primary[100];

  if (!props.item.canRender) {
    return <></>;
  }

  return (
    <ListItem
      sx={{
        flexDirection: 'column',
        alignItems: 'stretch',
        borderRadius: '4px',
        p: 0,
        // pr: 6,
        backgroundColor: colourToUse(),
      }}
    >
      <ListItemButton onClick={props.onClick}>
        <Box display='flex'
          flexDirection='row'>
          <ListItemIcon
            sx={{
              minWidth: 0,
              fontSize: 8,
              color: `${showAsHighlighted ? 'common.white' : 'primary.light'}`,
            }}
          >
            {props.item.variant == 'customIcon' ? props.item.icon : <SvgIcon 
              htmlColor={showAsHighlighted ? theme.palette.primary.contrastText : unSelectedColor}>
              {props.item.icon}
            </SvgIcon>}
          </ListItemIcon>
          <ListItemText
            primary={t(props.item.translationKey ?? '')}
            primaryTypographyProps={{
              color: `${showAsHighlighted ? theme.palette.primary.contrastText : unSelectedColor}`,
              fontSize: 12,
              pl: 8,
            }}
          />
        </Box>
      </ListItemButton>
    </ListItem>
  );
};