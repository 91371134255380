import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CssBaseline, GlobalStyles } from '@mui/material';
import './i18n';
import './assets/fonts/SoDo-Sans/SoDoSans-Regular.ttf';
import './assets/fonts/SoDo-Sans/SoDoSans-SemiBold.ttf';
import './assets/fonts/SoDo-Sans/SoDoSans-Bold.ttf';
import { ThemeProviderContext } from './theme/context/themeProviderContext';

const globalStyles = (
  <GlobalStyles
    styles={{
      'html,body,#root,.App': {
        width: '100%',
        height: '100%',
      },
      '#root': {
        display: 'flex',
      },
    }}
  />
);

const Index = () => {
  return (
    <ThemeProviderContext>
      <CssBaseline />
      {globalStyles}
      <App />
    </ThemeProviderContext>
  );
};

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/serviceworker.js');
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <Index />,
);

reportWebVitals();
