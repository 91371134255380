import { PaletteColor, palette } from '../palette';
import { typography } from '../typography';

const getPrimaryColor = () => {
  const brandingJson = localStorage.getItem('branding');
  if(brandingJson) {
    const branding = JSON.parse(brandingJson);
    return branding?.primaryColors ?? palette.defaultPrimary; 
  }
  return palette.defaultPrimary;
};

const GetPrimaryStyleByColor = (color: PaletteColor) => {
  return {
    color: color.contrastText,
    backgroundColor: color[500],
    borderColor: color[500],
    '&:hover': {
      backgroundColor: color[700],
      borderColor: color[700],
    },
  };
};

const GetSecondaryStyleByColor = (color: PaletteColor) => {
  return {
    color: color[700],
    stroke: color[700],
    backgroundColor: palette.common.white,
    borderColor: color[300],
    '&:hover': {
      color: color[800],
      backgroundColor: color[50],
      borderColor: color[300],
    },
  };
};

const goodFocusShadow = {
  '&:focus': {
    boxShadow:
      '0px 0px 0px 4px rgba(158, 119, 237, 0.24), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  },
};

const badFocusShadow = {
  '&:focus': {
    boxShadow:
      '0px 0px 0px 4px rgba(240, 68, 56, 0.24), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  },
};

const primaryDisableColor = {
  '&:disabled': {
    backgroundColor: palette.custom.gray[100],
  },
};

const secondaryDisableColor = {
  '&:disabled': {
    backgroundColor: palette.common.white,
  },
};

const primaryColors = {
  ...GetPrimaryStyleByColor(getPrimaryColor()),
  ...goodFocusShadow,
  ...primaryDisableColor,
};

const secondaryColors = {
  ...GetSecondaryStyleByColor(palette.custom.gray),
  ...goodFocusShadow,
  ...secondaryDisableColor,
};

const badColors = {
  ...GetPrimaryStyleByColor(palette.error),
  ...badFocusShadow,
  ...primaryDisableColor,
};

const badSecondaryColors = {
  ...GetSecondaryStyleByColor(palette.error),
  ...badFocusShadow,
  ...secondaryDisableColor,
};

const xxl = {
  ...typography.textLG,
  padding: '16px 22px',
  gap: '10px',
  height: '60px',
  '& .MuiButton-startIcon .MuiSvgIcon-root': {
    height: '24px',
    width: '24px',
  },
};

const xxlIcon = {
  ...xxl,
  padding: '16px',
  minWidth: '60px',
};

const xl = {
  ...typography.textMD,
  padding: '12px 18px',
  gap: '6px',
  height: '48px',
  '& .MuiButton-startIcon .MuiSvgIcon-root': {
    height: '20px',
    width: '20px',
  },
};

const xlIcon = {
  ...xl,
  padding: '12px',
  minWidth: '48px',
  '& .MuiButton-startIcon .MuiSvgIcon-root': {
    position: 'relative',
    height: '20px',
    width: '20px',
    left: '1px',
  },
};

const lg = {
  ...typography.textSM,
  padding: '10px 14px',
  gap: '4px',
  height: '40px',
  '& .MuiButton-startIcon .MuiSvgIcon-root': {
    height: '20px',
    width: '20px',
  },
};

const lgIcon = {
  ...lg,
  padding: '10px',
  minWidth: '40px',
  '& .MuiButton-startIcon .MuiSvgIcon-root': {
    position: 'relative',
    height: '20px',
    width: '20px',
  },
};

const md = {
  ...typography.textSM,
  padding: '8px 12px',
  gap: '2px',
  height: '36px',
  '& .MuiButton-startIcon .MuiSvgIcon-root': {
    height: '18px',
    width: '18px',
  },
};

const mdIcon = {
  ...md,
  padding: '8px',
  minWidth: '36px',
  '& .MuiButton-startIcon .MuiSvgIcon-root': {
    position: 'relative',
    height: '18px',
    width: '18px',
  },
};

export const MuiButton = {
  defaultProps: {
    variant: 'primary',
    size: 'xl',
  },
  styleOverrides: {
    root: {
      ...primaryColors,
      ...xxl,
      borderRadius: '8px',
      border: '1px solid',
      display: 'inline-flex',
      textTransform: 'none',
      fontWeight: 600,
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      '& .MuiButton-startIcon': {
        margin: '0px',
      },
      '&:disabled': {
        color: palette.custom.gray[400],
        borderColor: palette.custom.gray[200],
      },
    },
  },
  variants: [
    {
      props: { variant: 'primary', size: 'xxl' },
      style: { ...primaryColors, ...xxl },
    },
    {
      props: { variant: 'primaryIconOnly', size: 'xxl' },
      style: { ...primaryColors, ...xxlIcon },
    },
    {
      props: { variant: 'primary', size: 'xl' },
      style: { ...primaryColors, ...xl },
    },
    {
      props: { variant: 'primaryIconOnly', size: 'xl' },
      style: { ...primaryColors, ...xlIcon },
    },
    {
      props: { variant: 'primary', size: 'lg' },
      style: { ...primaryColors, ...lg },
    },
    {
      props: { variant: 'primaryIconOnly', size: 'lg' },
      style: { ...primaryColors, ...lgIcon },
    },
    {
      props: { variant: 'secondary', size: 'xxl' },
      style: { ...secondaryColors, ...xxl },
    },
    {
      props: { variant: 'secondaryIconOnly', size: 'xxl' },
      style: { ...secondaryColors, ...xxlIcon },
    },
    {
      props: { variant: 'secondary', size: 'xl' },
      style: { ...secondaryColors, ...xl },
    },
    {
      props: { variant: 'secondaryIconOnly', size: 'xl' },
      style: { ...secondaryColors, ...xlIcon },
    },
    {
      props: { variant: 'secondary', size: 'lg' },
      style: { ...secondaryColors, ...lg },
    },
    {
      props: { variant: 'secondaryIconOnly', size: 'lg' },
      style: { ...secondaryColors, ...lgIcon },
    },
    {
      props: { variant: 'bad', size: 'xxl' },
      style: { ...badColors, ...xxl },
    },
    {
      props: { variant: 'badIconOnly', size: 'xxl' },
      style: { ...badColors, ...xxlIcon },
    },
    {
      props: { variant: 'bad', size: 'xl' },
      style: { ...badColors, ...xl },
    },
    {
      props: { variant: 'badIconOnly', size: 'xl' },
      style: { ...badColors, ...xlIcon },
    },
    {
      props: { variant: 'bad', size: 'lg' },
      style: { ...badColors, ...lg },
    },
    {
      props: { variant: 'badIconOnly', size: 'lg' },
      style: { ...badColors, ...lgIcon },
    },
    {
      props: { variant: 'badSecondary', size: 'xxl' },
      style: { ...badSecondaryColors, ...xxl },
    },
    {
      props: { variant: 'badSecondaryIconOnly', size: 'xxl' },
      style: { ...badSecondaryColors, ...xxlIcon },
    },
    {
      props: { variant: 'badSecondary', size: 'xl' },
      style: { ...badSecondaryColors, ...xl },
    },
    {
      props: { variant: 'badSecondaryIconOnly', size: 'xl' },
      style: { ...badSecondaryColors, ...xlIcon },
    },
    {
      props: { variant: 'badSecondary', size: 'lg' },
      style: { ...badSecondaryColors, ...lg },
    },
    {
      props: { variant: 'badSecondaryIconOnly', size: 'lg' },
      style: { ...badSecondaryColors, ...lgIcon },
    },
    {
      props: { variant: 'primary', size: 'md' },
      style: { ...primaryColors, ...md },
    },
    {
      props: { variant: 'primaryIconOnly', size: 'md' },
      style: { ...primaryColors, ...mdIcon },
    },
    {
      props: { variant: 'secondary', size: 'md' },
      style: { ...secondaryColors, ...md },
    },
    {
      props: { variant: 'secondaryIconOnly', size: 'md' },
      style: { ...secondaryColors, ...mdIcon },
    },
    {
      props: { variant: 'bad', size: 'md' },
      style: { ...badColors, ...md },
    },
    {
      props: { variant: 'badIconOnly', size: 'md' },
      style: { ...badColors, ...mdIcon },
    },
    {
      props: { variant: 'badSecondary', size: 'md' },
      style: { ...badSecondaryColors, ...md },
    },
    {
      props: { variant: 'badSecondaryIconOnly', size: 'md' },
      style: { ...badSecondaryColors, ...mdIcon },
    },
  ],
};

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    primaryIconOnly: true;
    secondary: true;
    secondaryIconOnly: true;
    bad: true;
    badIconOnly: true;
    badSecondary: true;
    badSecondaryIconOnly: true;

    contained: false;
    outlined: false;
    text: false;
  }

  interface ButtonPropsSizeOverrides {
    small: false;
    medium: false;
    large: false;

    xxl: true;
    xl: true;
    lg: true;
    md: true;
  }
}