import './App.css';
import AuthGuard from './components/shared/AuthGuard';
import DashboardPage from './pages/Dashboard';
import LoginPage from './pages/Login';
import TransactionsPage from './pages/Transactions';
import { UserContextProvider } from './components/shared/useUser';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import Layout from './components/shared/layout/Layout';
import Reporting from './pages/Reporting';
import StockCountSchedule from './pages/StockCountSchedule';
import StockCountDetailsPage from './pages/StockCount';
import StockTransfersPage from './pages/StockTransfers';
import PurchaseOrdersPage from './pages/PurchaseOrders';
import CreateStockCount from './pages/CreateStockCount';
import StockCounts from './pages/StockCountDetails';
import StockCountReviewPage from './pages/StockCountReview';
import UomConversions from './pages/UomCoversions';
import RecipePage from './pages/Recipe';
import OrdersHistoryPage from './pages/Orders/OrdersHistory';
import QuickOrderPage from './pages/Orders/QuickOrder';
import OrderDetailsPage from './pages/Orders/OrderDetails';
import ProductsPage from './pages/Products';
import OrderrableItemsPage from './pages/OrderItems';
import StockOnHandPage from './pages/StockOnHand';
import ProductDetailsPage from './pages/ProductDetails';
import Invoices from './pages/Invoices';
import InvoiceDetails from './pages/InvoiceDetails';
import StockTransferDetailPage from './pages/StockTransfersDetails';
import WasteChecksheetPage from './pages/WasteChecksheet';
import WasteCheckSheetDetailsPage from './pages/WasteChecksheetDetail';
import ManageNotifications from './pages/ManageNotifications';
import ManageApplicationRoles from './pages/ManageApplicationRoles';
import ScheduledReports from './pages/ScheduledReports';
import CreateScheduledReports from './pages/CreateScheduledReports';
import ManageTransactions from './pages/TransactionsManagement';
import PurchaseOrdersDetails from './pages/PurchaseOrdersDetails';
import StockCountZonesPage from './pages/StockCountZones';
import OrderFormPage from './pages/Orders/OrderForm';
import Support from './pages/Support';
import { RecipeUpdates } from './pages/RecipeUpdates';
import UserManagement from './pages/ManageUsers';
import StockCountScheduleCreate from './pages/StockCountScheduleCreate';
import ManageConfigurations from './pages/ManageConfigurations';
import ErrorPage from './pages/ErrorPage';
import ManageCategories from './pages/ManageCategories';
import CreditRequest from './pages/CreditDetails';
import SourceTransactionDetails from './pages/SourceTransactions';
import ManageBranding from './pages/ManageBranding';

const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthGuard permissions={['User']}><Layout><DashboardPage /></Layout></AuthGuard>,
  },
  {
    path: '/reporting',
    element: <AuthGuard permissions={['User']}><Layout><Reporting /></Layout></AuthGuard>,
  },
  {
    path: '/store/stock-count',
    element: <AuthGuard permissions={['StockCountAdmin', 'StockCountRead']}><Layout><StockCounts /></Layout></AuthGuard>,
  },
  {
    path: '/store/create-stock-count',
    element: <AuthGuard permissions={['User']}><Layout><CreateStockCount /></Layout></AuthGuard>,
  },
  {
    path: '/recipe-updates',
    element: <AuthGuard permissions={['RecipeRead']}><Layout><RecipeUpdates /></Layout></AuthGuard>,
  },
  {
    path: '/store/review-stock-count/:id',
    element: <AuthGuard permissions={['User']}><Layout><StockCountReviewPage /></Layout></AuthGuard>,
  },
  {
    path: '/stock-count-schedule',
    element: <AuthGuard permissions={['StockCountAdmin', 'StockCountRead', 'StockListRead','StockLocationRead', 'StockLocationWrite', 'StockCountScheduleRead','StockCountScheduleWrite']}><Layout><StockCountSchedule /></Layout></AuthGuard>,
  },
  {
    path: '/invoices',
    element: <AuthGuard permissions={['InvoiceReconciliationWrite', 'InvoiceReconciliationAdmin', 'InvoiceReconciliationRead']}><Layout><Invoices/></Layout></AuthGuard>,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/store/perform-stock-count/:id',
    element: <AuthGuard permissions={['User']}><Layout><StockCountDetailsPage /></Layout></AuthGuard>,
  },
  {
    path: '/store/stock-transfers',
    element: <AuthGuard permissions={['TransferRead']}><Layout><StockTransfersPage /></Layout></AuthGuard>,
  },
  {
    path: '/store/stock-transfer/:id',
    element: <AuthGuard permissions={['TransferRead']}><Layout><StockTransferDetailPage /></Layout></AuthGuard>,
  },
  {
    path: '/store/po-receipt',
    element: <AuthGuard permissions={['PurchaseOrderRead']}><Layout><PurchaseOrdersPage /></Layout></AuthGuard>,
  },
  {
    path: '/store/orders-history',
    element: <AuthGuard permissions={['User']}><Layout><OrdersHistoryPage /></Layout></AuthGuard>,
  },
  {
    path: '/store/quick-order/:id',
    element: <AuthGuard permissions={['User']}><Layout><QuickOrderPage /></Layout></AuthGuard>,
  },
  {
    path: '/store/quick-order',
    element: <AuthGuard permissions={['User']}><Layout><QuickOrderPage /></Layout></AuthGuard>,
  },
  {
    path: '/store/order/:id',
    element: <AuthGuard permissions={['User']}><Layout><OrderDetailsPage /></Layout></AuthGuard>,
  },
  {
    path: '/store/products',
    element: <AuthGuard permissions={['RecipeRead']}><Layout><ProductsPage /></Layout></AuthGuard>,
  },
  {
    path: '/store/orderable-items',
    element: <AuthGuard permissions={['RecipeRead']}><Layout><OrderrableItemsPage /></Layout></AuthGuard>,
  },
  {
    path: '/store/order-form',
    element: <AuthGuard permissions={['User']}><Layout><OrderFormPage /></Layout></AuthGuard>,
  },
  {
    path: '/create-edit-schedule-stock-count',
    element: <AuthGuard permissions={['User']}><Layout><StockCountScheduleCreate /></Layout></AuthGuard>,
  },
  {
    path: '/create-edit-schedule-stock-count/:id',
    element: <AuthGuard permissions={['User']}><Layout><StockCountScheduleCreate /></Layout></AuthGuard>,
  },
  {
    path: '/support',
    element: <AuthGuard permissions={['User']}><Layout><Support /></Layout></AuthGuard>,
  },
  {
    path: '/transactions',
    element: <AuthGuard permissions={['TransactionRead']}><Layout><TransactionsPage /></Layout></AuthGuard>,
  },
  {
    path: '/transactions/:id',
    element: <AuthGuard permissions={['TransactionRead']}><Layout><TransactionsPage /></Layout></AuthGuard>,
  },
  {
    path: '/uom-conversions',
    element: <AuthGuard permissions={['ConversionRead']}><Layout><UomConversions /></Layout></AuthGuard>,
  },
  {
    path: '/uom-conversions/:itemNumber',
    element: <AuthGuard permissions={['ConversionRead']}><Layout><UomConversions /></Layout></AuthGuard>,
  },
  {
    path: '/store/stock-count-zones',
    element: <AuthGuard permissions={['User']}><Layout><StockCountZonesPage /></Layout></AuthGuard>,
  },
  {
    path: '/store/stock',
    element: <AuthGuard permissions={['StockCountRead']}><Layout><StockOnHandPage /></Layout></AuthGuard>,
  },
  {
    path: '/store/item/:id',
    element: <AuthGuard permissions={['User']}><Layout><ProductDetailsPage /></Layout></AuthGuard>,
  },
  {
    path: '/store/waste-checksheets',
    element: <AuthGuard permissions={['DailyWasteCheckSheetRead']}><Layout><WasteChecksheetPage /></Layout></AuthGuard>,
  },
  {
    path: '/store/daily-waste-checksheet/:id',
    element: <AuthGuard permissions={['DailyWasteCheckSheetRead']}><Layout><WasteCheckSheetDetailsPage /></Layout></AuthGuard>,
  },
  {
    path: '/store/recipes',
    element: <AuthGuard permissions={['RecipeRead']}><Layout><RecipePage /></Layout></AuthGuard>,
  },
  {
    path: '/manage-notifications',
    element: <AuthGuard permissions={['NotificationWrite']}><Layout><ManageNotifications /></Layout></AuthGuard>,
  },
  {
    path: '/manage-configurations',
    element: <AuthGuard permissions={['User']}><Layout><ManageConfigurations /></Layout></AuthGuard>,
  },
  {
    path: 'manage-configurations/branding',
    element: <AuthGuard permissions={['FranchiseDynamicBrandingWrite']}><Layout><ManageBranding /></Layout></AuthGuard>,
  },
  {
    path: '/manage-categories',
    element: <AuthGuard permissions={['User']}><Layout><ManageCategories /></Layout></AuthGuard>,
  },
  {
    path: '/manage-application-roles',
    element: <AuthGuard permissions={['RolesAdmin']} ><Layout><ManageApplicationRoles /></Layout></AuthGuard>,
  },
  {
    path: '/manage-transactions',
    element: <AuthGuard permissions={['TransactionAdmin']}><Layout><ManageTransactions /></Layout></AuthGuard>,
  },
  {
    path: '/store/transactions/source/:transactionSourceId/:transactionDate',
    element: <AuthGuard permissions={['SourceTransactionRead']}><Layout><SourceTransactionDetails /></Layout></AuthGuard>,
  },
  {
    path: '/invoices',
    element: <AuthGuard permissions={['User']}><Layout><Invoices /></Layout></AuthGuard>,
  },
  {
    path: '/invoice/:id',
    element: <AuthGuard permissions={['User']}><Layout><InvoiceDetails /></Layout></AuthGuard>,
  },
  {
    path: '/create-po-receipt/:id',
    element: <AuthGuard permissions={['User']}><Layout><PurchaseOrdersDetails /></Layout></AuthGuard>,
  },
  {
    path: '/store/credit-request/:id',
    element: <AuthGuard permissions={['User']}><Layout><CreditRequest /></Layout></AuthGuard>,
  },
  {
    path: '/user-management',
    element: <AuthGuard permissions={['UserAdmin']}><Layout><UserManagement /></Layout></AuthGuard>,
  },
  {
    path: '*',
    element: <AuthGuard permissions={['User']}><Layout><ErrorPage variant='404'/></Layout></AuthGuard>,
  },
  {
    path: '/scheduled-reports',
    element: <AuthGuard permissions={['User']}><Layout><ScheduledReports /></Layout></AuthGuard>,
  },
  {
    path: '/create-scheduled-reports/:id',
    element: <AuthGuard permissions={['User']}><Layout><CreateScheduledReports /></Layout></AuthGuard>,
  },
]);

const App = () => {
  return (
    <div className="App">
      <UserContextProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}
          adapterLocale="en-gb">
          <RouterProvider router={router} />
        </LocalizationProvider>
      </UserContextProvider>
    </div>
  );
};

export default App;
