import { Box, Typography } from '@mui/material';
import { theme } from '../../theme';

export const Pill = (props: {text: string}) => {
  return                <Box
    sx={{
      ml:4,
      display: 'inline-flex',
      p: '2px 8px',
      alignItems: 'center',
      borderRadius: '16px',
      border: '1px solid',
      fontWeight: 'normal',
      borderColor: theme.palette.primary[200],
      background: theme.palette.primary[50],
    }}
  >
    <Typography variant="textXS"
      sx={{ color: theme.palette.primary[700] }}>
      {props.text}
    </Typography>
  </Box>;

};