import { useContext, useEffect, useState } from 'react';
import { Grid, Button, SvgIcon, Alert, Snackbar, Typography } from '@mui/material';
import { Plus } from '../../assets';
import { useTranslation } from 'react-i18next';
import useLogError from '../../hooks/useLogError';
import { RecipeDialogProvider, useRecipeDialog } from '../../pages/Recipe/context/RecipeDialogContext';
import ItemsTab from './components/AllItems/ItemsTab';
import CreateRecipeDialog from '../../pages/Recipe/components/CreateRecipeDialog';
import { CreateMadeInStoreItemRequestDto } from '../../app/services/api/generated';
import useNswagClient from '../../hooks/api/useNswagClient';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';
import BackButton from '../../components/shared/actions/BackButton';
import { UserContext } from '../../components/shared/useUser';
import { Pill } from '../../components/shared/Pill';

const ItemTabs = () => {
  const { t } = useTranslation('products');
  const { hasPermissionTo } = useContext(UserContext);
  const [ hasPermissionToCreateRecipe, setHasPermissionToCreateRecipe ] = useState<boolean>(false);
  const { createMadeInStore } = useNswagClient();
  const { setShowRecipeDialog, updateFormField } = useRecipeDialog();
  const { logError } = useLogError();

  const [ isUpdating, setIsUpdating ] = useState<boolean>(false);
  const [ showSubmitStatusMessage, setShowSubmitStatusMessage ] = useState<boolean>(false);
  const [ isSubmittedSuccessfully, setIsSubmittedSuccessfully ] = useState(0);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ itemCount, setItemCount ] = useState<number | undefined>();

  useEffect(() => {
    setHasPermissionToCreateRecipe(hasPermissionTo(['CreateItemsAndRecipes']));
  }, []);


  const onCreateNew = () => {
    setShowRecipeDialog(true);
    updateFormField('selectedEntityType', 0);
  };

  const onSubmit = (item: CreateMadeInStoreItemRequestDto) => {
    setIsUpdating(true);
    createMadeInStore(item)
      .then(() => {
        setIsSubmittedSuccessfully((prev) => prev + 1);
      })
      .catch((error) => {
        logError(error);
        setErrorMessage(error);
        setIsSubmittedSuccessfully((prev) => prev + 1);
      })
      .finally(() => {
        setIsUpdating(false);
        setShowSubmitStatusMessage(true);
      });
  };
  
  return (
    <PageArea>
      <BackButton url="/" 
        title={t('back')}
        isLink
      />
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          alignSelf: 'stretch',
        }}>
        <PageTitle>{t('title')}</PageTitle>
        {itemCount &&
          <Pill text={itemCount + ' ' + t('items')}/>
        }
      </Typography>
      {hasPermissionToCreateRecipe &&
        (<Grid item
          xs={12}
          sx={{ textAlign: 'right', mb: 3 }}>
          <Button variant="primary"
            size="lg"
            startIcon={<SvgIcon> <Plus fill='white' /> </SvgIcon>}
            onClick={onCreateNew}
          >
            {t('createNew')}
          </Button>
        </Grid>
        )}
      <ItemsTab hasNewItemCreated={isSubmittedSuccessfully}
        itemCount={itemCount}
        setItemCount={setItemCount}/>
      <CreateRecipeDialog
        isUpdating={isUpdating}
        onCreateConfirm={onSubmit}
        onUpdateConfirm={() => {}}
        isComplete={isSubmittedSuccessfully}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showSubmitStatusMessage}
        onClose={() => setShowSubmitStatusMessage(false)}
        autoHideDuration={5000}
      >
        <Alert
          onClose={() => setShowSubmitStatusMessage(false)}
          severity={isSubmittedSuccessfully ? 'success' : 'error'}
          variant='filled'
          sx={{ width: '100%' }}
        >
          {isSubmittedSuccessfully ? t('successMessage') : t('errorMessage') + ' ' + errorMessage}
        </Alert>
      </Snackbar>
    </PageArea>
  );
};

const ProductsPage = () => {
  return (
    <RecipeDialogProvider>
      <ItemTabs />
    </RecipeDialogProvider>
  );
};

export default ProductsPage;