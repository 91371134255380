import { palette } from '../palette';

const getPrimaryColor = () => {
  const brandingJson = localStorage.getItem('branding');
  if (brandingJson) {
    const branding = JSON.parse(brandingJson);
    return branding?.primaryColors ?? palette.primary;
  }
  return palette.primary;
};

export const MuiDrawer = {
  styleOverrides: {
    root: {
      '& .MuiPaper-root': {
        backgroundColor: getPrimaryColor()[700],
        color: getPrimaryColor().contrastText,
        height: '100vh',
        border: '0px',
      },
      '& .AppSlidingSubMenu-root': {
        backgroundColor: getPrimaryColor()[800],
        color: getPrimaryColor().contrastText,
      },
      '& .MuiListItemIcon-root': {
        alignItems: 'center',
        color: getPrimaryColor().contrastText,
      },
      '& .MuiListItem-root': {
        padding: '0px',
      },
    },
  },
};