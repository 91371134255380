import {
  Box,
  Button,
  FormControl,
  Grid,
  SvgIcon,
  TextField,
} from '@mui/material';
import { PagedData_1OfWasteHeaders } from '../../../app/services/api/generated';
import { FC, useEffect, useState } from 'react';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { SearchSm } from '../../../assets';
import WasteChecksheetTable from './WasteChecksheetTable';
import { WasteChecksheetTab } from '../enums/WasteChecksheetTab';
import { WasteChecksheetFilterOptions } from '../interfaces/WasteChecksheetFilterOptions';

type Props = {
  wasteChecksheet: PagedData_1OfWasteHeaders,
  activeTab: WasteChecksheetTab,
  loadData: (options: WasteChecksheetFilterOptions) => void,
  currencyCode?: string;
}

const WasteChecksheetBox: FC<Props> = ({ wasteChecksheet, activeTab, loadData, currencyCode }) => {
  const defaultTake = 10;
  const defaultSkip = 0;

  const [date, setDate] = useState('');
  const [page, setPage] = useState(0);
  const { t } = useTranslation('wasteChecksheet');

  useEffect(() => {
    setDate('');
  }, [activeTab]);
  
  const callLoadDataFromAPI = (options: WasteChecksheetFilterOptions) => {
    loadData(options);
  };

  const loadDataFromWasteChecksheetTable = (skip: number, take: number) => {
    callLoadDataFromAPI({
      date: date,
      skip: skip,
      take: take,
      type: activeTab,
    });
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };

  const clearAll = () => {
    setDate('');
    callLoadDataFromAPI({
      date: '',
      skip: 0,
      take: 10,
      type: activeTab,
    });

    setPage(1);
  };

  const searchClick = () => {
    const formattedDate =  new Date(date).toISOString(); 
    callLoadDataFromAPI({
      date: formattedDate,
      skip: defaultSkip,
      take: defaultTake,
      type: activeTab,
    });

    setPage(1);
  };

  return <Box sx={{ border: '1px solid', borderColor: theme.palette.custom.gray[300] }}>
    <Grid container
      sx={{ py: '12px', px: '8px' }}
      spacing={5}>
      <Grid item
        lg={3}
        md={4}
        xs={12}>
        <FormControl fullWidth
          size='small'>
          <TextField id="waste-checksheet-date-filter"
            label={t('filterPanel.date')}
            type='date'
            size='small'
            variant="outlined"
            value={date}
            InputLabelProps={{ shrink: true }}
            onChange={handleDateChange} />
        </FormControl>
      </Grid>
      <Grid container
        justifyContent="flex-end"
        sx={{ pt: '12px', px: '8px', flexGrow: 1, width: 'auto' }}
        spacing={5}>
        <Grid item
          xs={12}
          md={4}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'right' }}>
          <Button
            variant="secondary"
            size="lg"
            onClick={clearAll}
            fullWidth
          >
            {t('filterPanel.clear')}
          </Button>
        </Grid>
        <Grid item
          xs={12}
          md={4}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'right' }}>
          <Button
            variant="primary"
            size="lg"
            startIcon={<SvgIcon> <SearchSm/> </SvgIcon>}
            onClick={searchClick}
            fullWidth
          >
            {t('filterPanel.search')}
          </Button>
        </Grid>
      </Grid>
      <Grid item
        xs={12}
        sx={{ mt: '20px' }} >
        <div style={{ overflowX: 'auto' }}>
          {wasteChecksheet &&
            <WasteChecksheetTable
              list={wasteChecksheet}
              setPageZero={page}
              loadData={loadDataFromWasteChecksheetTable}
              activeTab={activeTab}
              currencyCode={currencyCode}
            />
          }
        </div>
      </Grid>
    </Grid>
  </Box>;
};

export default WasteChecksheetBox;