import { isNonStarbucksEnvironment } from '../app/services/environment';
import { palette } from '../theme/palette';

const defaultBranding= {
  id: 0,
  name: 'Default Orderly Branding',
  franchiseId: 0,
  primaryColors: palette.defaultPrimary,
  secondaryColors: palette.defaultSecondary,
  dashboardImage: isNonStarbucksEnvironment() ? '/orderly-dashboard.jpg' : '/SustainabilityNewsBrief.jpg',
  logoImage: isNonStarbucksEnvironment() ? '/orderly-logo-circle.png' : '/starbucks.png',
};

export const useSelectedBranding = () => {
  const brandingJson = localStorage.getItem('branding');
  const branding = brandingJson ? JSON.parse(brandingJson) : defaultBranding;

  return branding;
};