import { IconButton, List, ListItem, SvgIcon, Tooltip, useTheme } from '@mui/material';
import { LogOut, SwitchHorizontal01 } from '../../../../../assets';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { UserContext } from '../../../../shared/useUser';
import { DrawerNavItem } from '../../interfaces/DrawerNavItem';
import { NavItem } from '../NavItem';

interface UserNavItemsListProps {
  variant?: 'default' | 'iconColumn',
  logOutOnClick: () => void,
  switchStoreOnClick: () => void;
}

export const UserNavItemsList = ({ variant, logOutOnClick, switchStoreOnClick }: UserNavItemsListProps) => {
  const theme = useTheme();
  const { t } = useTranslation('drawer');
  const { user } = useContext(UserContext);

  const userNavItems: DrawerNavItem[] = [
    {
      canRender: true,
      translationKey: 'switchStore',
      icon: <SwitchHorizontal01 />,
      onClick: switchStoreOnClick,
      tooltipText: 'selectStore',
    },
    {
      canRender: true,
      translationKey: 'logOut',
      icon: <LogOut />,
      onClick: logOutOnClick,
      tooltipText: 'logOut',
    },
  ];

  const renderIconColumn = (item: DrawerNavItem) => (
    <ListItem key={item.translationKey}
      sx={{ p: 1 }}
    >
      <IconButton onClick={item.onClick}
        sx={{ padding: 1, color: 'common.white' }}
      >
        <Tooltip title={t(`${item.tooltipText}`)}>
          <SvgIcon
            key={`user-nav-item-${item.translationKey}`}
            sx={{
              fontSize: 16,
              cursor: 'pointer',
            }}
            htmlColor={theme.palette.primary.contrastText}>
            {item.icon}
          </SvgIcon>
        </Tooltip>
      </IconButton>
    </ListItem>);

  const renderItem = (item: DrawerNavItem) => {
    switch (variant) {
    case 'iconColumn':
      return renderIconColumn(item);
    case 'default':
    default:
      return <NavItem item={item}
        onClick={item.onClick}/>;
    }
  };

  return (
    <List sx={{
      py: {
        xs: 0,
        sm: 5,
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    }}
    >
      {(user?.stores ?? []).length === 1 ? userNavItems.filter(item => item.translationKey !== 'switchStore').map(renderItem) : userNavItems.map(renderItem)}
    </List>);
};