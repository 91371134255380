import { Box, Paper, SvgIcon, Table, TableBody, TableCell, Select, MenuItem, TableContainer, TableHead, TableRow, Typography, SelectChangeEvent } from '@mui/material';
import { ReactNode, useContext, useEffect, useState } from 'react';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { CreditRequestValue } from '../../../app/services/api/generated';
import CustomPagination from './../../../components/forms/Pagination';
import { Pill } from './../../../components/shared/Pill';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../theme';
import { File06 } from '../../../assets';
import { UserContext } from './../../../components/shared/useUser';
import LoadingWheel from './../../../components/ui/LoadingWheel';

const ValueOfCreditReportsTable = () => {
  const [data, setData] = useState<CreditRequestValue[]>([]);
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const { selectedStore } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const { getCreditRequestValueData } = useNswagClient();
  const { t } = useTranslation('common');
  const [ selectAllStores, setSelectAllStores ] = useState<boolean>(false);

  useEffect(() => {
    getCreditRequestValueData(selectedStore?.storeNumber, selectAllStores).then((response) => {
      if(response.data) {
        setData(response?.data);   
      }
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [user]);

  const maxPages = () => {
    return Math.ceil(data.length / rowsPerPage);
  };

  const visibleRows = () => {
    return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  const handleSelectAllStoresChange = (event: SelectChangeEvent<number>, child:ReactNode) => {
    const shouldSelectAllStores = event.target.value === 1;
    setSelectAllStores(shouldSelectAllStores);
    getCreditRequestValueData(selectedStore?.storeNumber, shouldSelectAllStores);
  };

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', m: '12px', borderRadius: '12px', border: `1px solid ${theme.palette.custom.gray[200]}`, boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)' }}>
      <Box
        sx={{
          textAlign: 'left',
          p: '12px',
          color: 'black',
          mb: '2px',
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          alignSelf: 'stretch',
        }}
      >
        <SvgIcon sx={{ width: '24px' }}><File06 /></SvgIcon>
        <Typography variant="textLG">{' ' + t('valueOfCreditRequests')}</Typography>
        {
          !isLoading &&
            <Pill text={`${data.length} ${t('items')}`}/>
        }
        {
          !isLoading &&
            <Box sx={{ marginLeft: 'auto' }}>
              <Select defaultValue={0}
                onChange={handleSelectAllStoresChange}>
                <MenuItem value={0}>{t('currentStore')}</MenuItem>
                <MenuItem value={1}>{t('allStores')}</MenuItem>
              </Select>
            </Box>
        }
      </Box>

      {
        isLoading ?
          <LoadingWheel /> :
          <>
            <TableContainer component={'div'}>
              <Table
                size="small"
                aria-label="Table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {t('storeNumber')}
                    </TableCell>
                    <TableCell align="left">
                      {t('storeName')}
                    </TableCell>
                    <TableCell align="left">
                      {t('orderNumber')}
                    </TableCell>
                    <TableCell align="left">
                      {t('numberOfItems')}
                    </TableCell>
                    <TableCell align="left">
                      {t('creditAmount')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    visibleRows().map((row) => (
                      <TableRow key={row.orderNumber}>
                        <TableCell
                          component="th"
                          scope="row">
                          {row.storeNumber}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row">
                          {row.storeName}
                        </TableCell>
                        <TableCell
                          align="left">
                          {(row.orderNumber)}
                        </TableCell>
                        <TableCell
                          align="left">
                          {(row.numberOfItems)}
                        </TableCell>
                        <TableCell
                          align="left">
                          {row.creditAmount}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {
              visibleRows().length > 0 ?
                <CustomPagination
                  page={page}
                  setPage={setPage}
                  maxPages={maxPages()}
                  breakpointForChangeDisplay={120000}
                ></CustomPagination>
                :
                <Typography sx={{ p: '12px' }}>{t('noData')}</Typography> 
            }
          </>
      }
    </Paper>
  );
};

export default ValueOfCreditReportsTable;